import './src/scss/index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ErrorBoundary } from "@sentry/gatsby";
import React from 'react';

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary showDialog>
      {element}
    </ErrorBoundary>
  );
};